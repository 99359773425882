export const NEWSLETTER_SOURCE = {
  FL: 'FL'
}

export const NEWSLETTER = {
  [NEWSLETTER_SOURCE.FL]: {
    MAIN: 'FlAudience',
    EDUCATION: 'FlEducationInterest',
    TRADING_OFFERS: 'FlTradingOffersInterest'
  }
}

export const NEWSLETTER_TITLE = {
  [NEWSLETTER_SOURCE.FL]: {
    [NEWSLETTER.FL.MAIN]: 'Daily newsletter',
    [NEWSLETTER.FL.TRADING_OFFERS]: 'Trading offers from relevant providers',
    [NEWSLETTER.FL.EDUCATION]: 'Education'
  }
}
