<template>
  <div
    v-if="isVideoBannerVisible"
    v-focusable="{ attachTabIndex: false }"
    ref="videoBanner"
    :class="wrapperClasses"
    :tabindex="currentTabindex"
    @focus="setFocusToIframe"
  />
</template>

<script>
import { VIDEO_BANNER_SETTINGS_BY_BANNER_TYPE } from 'enums/video-banner'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { BROWSER_NAME } from 'enums/browser'
import * as types from '@/store/mutation-types'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { QUERY_PARAM } from 'enums/query-param'
import { VIDEO_BANNER_WRAPPER_CLASS } from 'shared/AVideoBanner/enums'

const MINIMUM_SUPPORTED_VERSION_BY_BROWSER_NAME = {
  [BROWSER_NAME.SAFARI]: 12,
  [BROWSER_NAME.MOBILE_SAFARI]: 12
}

export default {
  name: 'AVideoBanner',
  mixins: [hydrationHelpers],
  props: {
    videoBannerType: propValidator([PROP_TYPES.STRING])
  },
  data() {
    return {
      currentPlayerId: null,
      currentTabindex: 0
    }
  },
  computed: {
    ...mapGetters({
      browserDetails: 'browserDetails',
      executeHandlersOnConsentChange:
        'one-trust/executeHandlersOnConsentChange',
      isPreviewMode: 'isPreviewMode'
    }),
    wrapperClasses() {
      return {
        mocked: this.isMocked,
        [VIDEO_BANNER_WRAPPER_CLASS]: true
      }
    },
    settings() {
      return VIDEO_BANNER_SETTINGS_BY_BANNER_TYPE[this.videoBannerType]
    },
    playerApiId() {
      return this.settings.playerApiId
    },
    playerId() {
      return this.$_hydrationHelpers_isLayoutMobileOrTablet
        ? this.settings.playerIdMobile
        : this.settings.playerIdDesktop
    },
    isMocked() {
      return (
        this.isPreviewMode ||
        !!this.$route.query[QUERY_PARAM.DISABLE_VIDEO_BANNER]
      )
    },
    isVideoBannerVisible() {
      const minimumSupportedCurrentBrowserVersion =
        MINIMUM_SUPPORTED_VERSION_BY_BROWSER_NAME[this.browserDetails?.name]

      if (!minimumSupportedCurrentBrowserVersion) return true

      return +this.browserDetails.major >= minimumSupportedCurrentBrowserVersion
    }
  },
  watch: {
    $_hydrationHelpers_isLayoutMobileOrTablet: {
      handler: function() {
        if (
          process.server ||
          this.isMocked ||
          !this.isVideoBannerVisible ||
          this.playerId === this.currentPlayerId
        ) {
          return
        }

        this.$_videoBanner_removeVideoBannerFromContainer()
        this.$_videoBanner_setFloatingVideoBannerAsNotDisplayed()
        this.$_videoBanner_init()
      }
    }
  },
  methods: {
    ...mapMutations({
      updateHandlersToExecuteOnConsentChange: `one-trust/${types.UPDATE_HANDLERS_TO_EXECUTE_ON_CONSENT_CHANGE}`,
      removeHandlersForEntityType: `one-trust/${types.REMOVE_HANDLERS_FOR_ENTITY_TYPE}`
    }),
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    /**
     * We manually switch focus to the iframe element to bypass a specific issue:
     * When we listen for a blur event on container and the focus is set to the
     * video banner's iframe, the "relatedTarget" in this case is "null". This
     * prevents us from detecting that the focus was set to an element outside
     * of the target container. To avoid this, we make the wrapper focusable,
     * so it is visible in "relatedTarget".
     */
    setFocusToIframe() {
      const iframe = this.$el.querySelector('iframe')

      if (iframe) {
        iframe.focus()
      }
    },
    /**
     * When any child of this.$el is in focus, we need to make the container
     * non-focusable, to prevent an unexpected focus trap loop, when focus
     * back (using shift + tab) permanently calls setFocusToIframe
     *
     * Note: we can't use "focusin" event on parent, because when focus is
     * set to an iframe, it is undetectable by such method. Instead, we listen
     * for a global blur event and check if the element we blurred to belongs to
     * the current container.
     */
    onGlobalBlur() {
      requestAnimationFrame(() => {
        if (this.$el.contains(document.activeElement)) {
          this.currentTabindex = -1
        } else {
          this.currentTabindex = 0
        }
      })
    },
    $_videoBanner_addScriptToContainer() {
      const videoBannerScript = document.createElement('script')
      videoBannerScript.src = `https://live.sekindo.com/live/liveView.php?s=${this.playerId}`
      videoBannerScript.type = 'text/javascript'
      videoBannerScript.setAttribute('language', 'javascript')
      this.$refs.videoBanner.appendChild(videoBannerScript)
    },
    $_videoBanner_init() {
      if (
        this.isMocked ||
        !this.playerId ||
        !this.isVideoBannerVisible ||
        this.playerId === this.currentPlayerId
      ) {
        return
      }

      this.currentPlayerId = this.playerId

      this.removeHandlersForEntityType(COOKIE_ENTITY_TYPE.VIDEO_BANNER)

      this.registerHandlerThatRequiresConsent(
        createOneTrustHandlerInstance({
          handler: this.$_videoBanner_addScriptToContainer,
          entityType: COOKIE_ENTITY_TYPE.VIDEO_BANNER
        })
      )
    },
    /**
     * TODO investigate other approaches to remove the widget
     */
    $_videoBanner_removeVideoBannerFromContainer() {
      if (!this.isVideoBannerVisible) return

      this.$refs.videoBanner.innerHTML = ''
    },
    $_videoBanner_setFloatingVideoBannerAsNotDisplayed() {
      window.sekindoFlowingPlayerOn = false
    }
  },
  mounted() {
    // TODO remove or keep this code as a reference
    // window.addEventListener('primisPlayerInit', function(e) {
    //   console.log('here: ', e)
    //   if (e.detail.playerApiId === this.playerId) {
    //     var primisPlayer = e.detail
    //     console.log('primisPlayer: ', primisPlayer)
    //   }
    // })
    this.$_videoBanner_init()

    document.addEventListener('blur', this.onGlobalBlur, true)
  },
  beforeDestroy() {
    this.$_videoBanner_setFloatingVideoBannerAsNotDisplayed()

    document.removeEventListener('blur', this.onGlobalBlur, true)
  }
}
</script>

<style lang="scss" scoped>
.video-banner__wrapper {
  &.mocked {
    @include mockedBanner($title: 'Video Banner');
    height: 300px;
  }
}
</style>
