<template>
  <a-sticky class="a-global-time__sticky-wrapper" :class="wrapperDynamicClass">
    <div class="a-global-time__wrapper">
      <div
        v-for="time in globalTimes"
        :key="time.label"
        class="a-global-time__single-time"
      >
        <span class="a-global-time__time-label">{{ time.label }} </span>
        <span class="a-global-time__time-value">{{ time.value }}</span>
      </div>
    </div>
  </a-sticky>
</template>

<script>
import { DateTime } from 'luxon'
import { generateServerCacheKey } from '@/plugins/helper'
import { mapGetters } from 'vuex'
import ASticky from 'shared/ASticky'

const INTERVAL_PERIOD = 333
const DEFAULT_TIME_VALUE = '00:00:00'

const IANA_TIMEZONE = {
  UTC: 'UTC',
  EUROPE_LONDON: 'Europe/London',
  AMERICA_NEW_YORK: 'America/New_York',
  ASIA_TOKYO: 'Asia/Tokyo',
  AUSTRALIA_SYDNEY: 'Australia/Sydney'
}

export default {
  name: 'GlobalTime',
  serverCacheKey: () => generateServerCacheKey('GlobalTime'),
  components: { ASticky },
  data() {
    return {
      intervalId: null,
      globalTimes: [
        {
          label: 'GMT',
          timezone: IANA_TIMEZONE.UTC,
          value: DEFAULT_TIME_VALUE
        },
        {
          label: 'LON',
          timezone: IANA_TIMEZONE.EUROPE_LONDON,
          value: DEFAULT_TIME_VALUE
        },
        {
          label: 'NY',
          timezone: IANA_TIMEZONE.AMERICA_NEW_YORK,
          value: DEFAULT_TIME_VALUE
        },
        {
          label: 'TKYO',
          timezone: IANA_TIMEZONE.ASIA_TOKYO,
          value: DEFAULT_TIME_VALUE
        },
        {
          label: 'SYD',
          timezone: IANA_TIMEZONE.AUSTRALIA_SYDNEY,
          value: DEFAULT_TIME_VALUE
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isHeaderCollapsed: 'isHeaderCollapsed'
    }),
    wrapperDynamicClass() {
      return {
        'header-collapsed': this.isHeaderCollapsed
      }
    }
  },
  methods: {
    startInterval() {
      this.intervalId = setInterval(this.refreshGlobalTimes, INTERVAL_PERIOD)
    },
    refreshGlobalTimes() {
      const date = DateTime.local()
      this.globalTimes = this.globalTimes.map(time => ({
        ...time,
        value: date
          .setZone(time.timezone)
          .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
      }))
    }
  },
  mounted() {
    this.startInterval()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
</script>

<style lang="scss">
.a-global-time__sticky-wrapper {
  top: $height-header-expanded;
  z-index: $z-index-global-time;
  transition: top 0.3s ease;

  &.header-collapsed {
    top: $height-header-collapsed;
  }
}

.a-global-time__wrapper {
  width: 100%;
  height: 50px;
  background: $c--background;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  @include sidebar-small-size {
    height: 75px;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 15px 15px 20px;
  }

  .a-global-time__single-time {
    flex-shrink: 0;

    @include sidebar-small-size {
      margin-right: 12px;
    }

    &:nth-child(3) {
      @include sidebar-small-size {
        margin-right: 0;
      }
    }
  }

  .a-global-time__time-label,
  .a-global-time__time-value {
    font-family: $font-sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }

  .a-global-time__time-value {
    color: $c--main;
  }
}
</style>
