<template>
  <div class="newsletter-success-message__wrapper">
    <div class="newsletter-success-message__icon">
      <a-icon
        :icon="ICON.SUCCESS_ROUND_BLUE"
        :width="30"
        :height="30"
        original
        no-hover
      />
    </div>

    <div>
      <p class="newsletter-success-message__title blue bold">
        {{ formSettings.SuccessMessageTitle }}
      </p>

      <p class="newsletter-success-message__text blue">
        {{ formSettings.SuccessMessageText }}
      </p>
    </div>
  </div>
</template>

<script>
import AIcon, { ICON } from 'shared/AIcon'
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'NewsletterWidgetSuccessMessage',
  components: { AIcon },
  props: {
    formSettings: propValidator([PROP_TYPES.OBJECT], false, () => ({}))
  },
  data() {
    return {
      ICON
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-success-message__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;

  .newsletter-success-message__icon {
    margin: 0 8px 8px 0;
  }

  .newsletter-success-message__title {
    font-family: $font-roboto-condensed;
    font-size: 18px;
  }

  .newsletter-success-message__text {
    margin-top: 5px;
    font-family: $font-roboto-condensed;
    font-size: 17px;
  }
}
</style>
