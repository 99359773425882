import { REFS } from 'enums/external-refs'
import { getLinkToFmBucketFile } from '@/plugins/helper'
import { POPUP_POSITION } from 'enums/onboarding/general'

export const EBOOK_DUMMY_CLASS = 'onboarding-ebook-placeholder'

const DUMMY_EBOOK_IMAGE_DIMENSIONS = {
  WIDTH: 260,
  HEIGHT: 145
}

let ebookImage = null

function isDummyEbook(ebookElement) {
  return ebookElement.className.includes(EBOOK_DUMMY_CLASS)
}

function getDummyEbookWrapper() {
  return (
    document.querySelector(
      `[data-ref="${REFS.EBOOK_DUMMY_WRAPPER_SIDEBAR}"]`
    ) ||
    document.querySelector(
      `[data-ref="${REFS.EBOOK_DUMMY_WRAPPER_ARTICLE_BODY}"]`
    )
  )
}

function getRealEbookWrapper() {
  return Array.from(
    document.querySelectorAll(`[data-ref="${REFS.EBOOK_WRAPPER}"]`)
  ).find(el => window.getComputedStyle(el).display !== 'none')
}

function getRealOrDummyEbookToHighlight() {
  return getRealEbookWrapper() || getDummyEbookWrapper()
}

function setDummyEbookForOnboarding(ebookWrapper, document) {
  ebookImage = document.createElement('img')
  ebookImage.style.width = `${DUMMY_EBOOK_IMAGE_DIMENSIONS.WIDTH}px`
  ebookImage.style.height = `${DUMMY_EBOOK_IMAGE_DIMENSIONS.HEIGHT}px`
  ebookImage.style.margin = '20px auto'
  ebookImage.style.display = 'block'
  ebookImage.alt = 'ebook-hint'
  ebookImage.src = getLinkToFmBucketFile('ebook-hint.jpg')

  ebookWrapper.appendChild(ebookImage)
}

export function removeDummyEbook() {
  const dummyEbookWrappers = [
    document.querySelector(`[data-ref="${REFS.EBOOK_DUMMY_WRAPPER_SIDEBAR}"]`),
    document.querySelector(
      `[data-ref="${REFS.EBOOK_DUMMY_WRAPPER_ARTICLE_BODY}"]`
    )
  ]

  dummyEbookWrappers
    .filter(wrapper => wrapper)
    .forEach(wrapper => {
      wrapper.innerHTML = ''
    })

  if (!ebookImage) return

  ebookImage = null
}

export function getEbookElementStepSettings({ document }) {
  let ebookToHighlight = getRealOrDummyEbookToHighlight()

  if (!ebookToHighlight) return null

  if (isDummyEbook(ebookToHighlight)) {
    setDummyEbookForOnboarding(ebookToHighlight, document)
    ebookToHighlight = ebookToHighlight.querySelector('img')
  }

  return {
    element: ebookToHighlight,
    intro: `Utilize the EBook and see what the personalized lead collector can do for your company. 
      Learn how to promote your business and convert traffic today.`,
    position: POPUP_POSITION.LEFT
  }
}
