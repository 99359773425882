<template>
  <div :class="wrapperClass">
    <a-form
      hide-title
      hide-description
      :is-term-accepted.sync="isTermAccepted"
      :captcha-key.sync="captchaKey"
      :form-type="$options.consts.FORM_TYPE.NEWSLETTER"
      :in-progress="inProgress"
      :form-validation-fn="$_componentValidation_isFormValid"
      :errors="$v"
      :disable-captcha="!isCaptchaEnabled"
      :form-id="$options.consts.FORM_ID.NEWSLETTER_SIDEBAR"
      class="newsletter__form"
      ref="form"
      @submit="submitForm"
      @click.native="enableCaptcha"
    >
      <a-lazy-hydrate when-visible>
        <div class="newsletter-widget__title-section">
          <a-icon
            :icon="$options.consts.ICON.ENVELOPE_ROUND_BLUE"
            original
            :height="30"
            :width="30"
            class="newsletter-widget__icon"
          />
          <span class="newsletter-widget__title"
            >Get the Forexlive newsletter</span
          >
        </div>
      </a-lazy-hydrate>
      <a-lazy-hydrate when-visible>
        <div class="newsletter-widget__fields-section">
          <a-text-input
            v-model="userForm.Email"
            type="email"
            placeholder="Your email"
            :input-style="$options.consts.INPUT_STYLE.STYLE_4"
            hide-label
            :error="$v.userForm.Email"
            class="newsletter-widget__input email"
            @focus="expandWidget"
          />
          <a-text-input
            v-model="userForm.FirstName"
            type="text"
            placeholder="First name"
            maxLength="100"
            :input-style="$options.consts.INPUT_STYLE.STYLE_4"
            hide-label
            :error="$v.userForm.FirstName"
            class="newsletter-widget__input"
          />
          <a-text-input
            v-model="userForm.LastName"
            type="text"
            placeholder="Last name"
            maxLength="100"
            :input-style="$options.consts.INPUT_STYLE.STYLE_4"
            hide-label
            :error="$v.userForm.LastName"
            class="newsletter-widget__input"
          />
        </div>
      </a-lazy-hydrate>
      <p
        class="newsletter-widget__checkboxes-section-title text-caption semi-bold"
      >
        Select additional content:
      </p>
      <a-lazy-hydrate when-visible>
        <div class="newsletter-widget__checkboxes-section">
          <a-checkbox
            v-for="checkbox in checkboxes"
            v-model="checkbox.isSelected"
            :key="checkbox.value"
            :checkbox-size="$options.consts.CHECKBOX_SIZE.SMALL"
            class="newsletter-widget__checkbox"
          >
            {{ checkbox.title }}
          </a-checkbox>
        </div>
      </a-lazy-hydrate>
      <a-gdpr
        slot="gdpr"
        :gdpr="formSettings.Gdpr"
        v-model="isTermAccepted"
        :error="$v.isTermAccepted"
        :checkbox-size="$options.consts.CHECKBOX_SIZE.SMALL"
        class="newsletter-widget__gdpr"
      />
      <a-lazy-hydrate when-visible>
        <a-button
          slot="buttons"
          slot-scope="{ isCaptchaLoaded }"
          type="submit"
          :in-progress="inProgress"
          :disabled="!isCaptchaLoaded"
          class="newsletter-widget__button"
          >SIGN UP</a-button
        >
      </a-lazy-hydrate>
      <a-lazy-hydrate when-visible>
        <newsletter-widget-success-message
          slot="successMessage"
          :form-settings="formSettings"
        />
      </a-lazy-hydrate>
    </a-form>
    <div class="newsletter-widget__bottom-overlay"></div>
  </div>
</template>

<script>
import { required, email, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

import AForm, { FORM_TYPE } from 'shared/AForm'
import AIcon, { ICON } from 'shared/AIcon'
import { INPUT_STYLE } from 'shared/ATextInput'
import {
  NEWSLETTER_SOURCE,
  NEWSLETTER,
  NEWSLETTER_TITLE
} from 'enums/newsletters'
import mixins from '@/utils/mixins'
import { CHECKBOX_SIZE } from 'shared/ACheckbox'
import AGdpr from 'shared/AGdpr'
import NewsletterWidgetSuccessMessage from '@/components/_layout/ASidebar/NewsletterWidget/NewsletterWidgetSuccessMessage'
import { FORM_ID } from 'enums/form-id'

export default {
  name: 'NewsletterWidget',
  mixins: [mixins.componentValidation],
  components: { NewsletterWidgetSuccessMessage, AForm, AIcon, AGdpr },
  consts: {
    FORM_ID,
    FORM_TYPE,
    ICON,
    INPUT_STYLE,
    CHECKBOX_SIZE,
    resetComponentDataFields: [
      'isTermAccepted',
      'isWidgetExpanded',
      'captchaKey',
      'inProgress',
      'userForm',
      'checkboxes',
      'isCaptchaEnabled'
    ]
  },
  data() {
    return {
      isTermAccepted: false,
      isWidgetExpanded: false,
      captchaKey: '',
      inProgress: false,
      userForm: {
        FirstName: '',
        LastName: '',
        Email: ''
      },
      checkboxes: [
        {
          title:
            NEWSLETTER_TITLE[NEWSLETTER_SOURCE.FL][NEWSLETTER.FL.EDUCATION],
          value: NEWSLETTER[NEWSLETTER_SOURCE.FL].EDUCATION,
          isSelected: false
        },
        {
          title:
            NEWSLETTER_TITLE[NEWSLETTER_SOURCE.FL][
              NEWSLETTER.FL.TRADING_OFFERS
            ],
          value: NEWSLETTER[NEWSLETTER_SOURCE.FL].TRADING_OFFERS,
          isSelected: false
        }
      ],
      isCaptchaEnabled: false
    }
  },
  validations: {
    userForm: {
      FirstName: {
        'maxLength(100)': maxLength(100)
      },
      LastName: {
        'maxLength(100)': maxLength(100)
      },
      Email: {
        required,
        email,
        'maxLength(100)': maxLength(100)
      }
    },
    isTermAccepted: {
      acceptTerms: val => val
    }
  },
  computed: {
    ...mapGetters({
      getFormSettingsByType: 'leads/getFormSettingsByType'
    }),
    formSettings() {
      return this.getFormSettingsByType(FORM_TYPE.NEWSLETTER)
    },
    wrapperClass() {
      return [
        'newsletter-widget__wrapper',
        { 'newsletter-widget__wrapper--expanded': this.isWidgetExpanded }
      ]
    }
  },
  methods: {
    ...mapActions({
      sendNewsletter: 'leads/sendNewsletter'
    }),
    async submitForm() {
      if (this.$helper.isValidationFailed(this)) {
        return
      }

      try {
        this.inProgress = true

        const payload = {
          FirstName: this.userForm.FirstName,
          LastName: this.userForm.LastName,
          Email: this.userForm.Email,
          Subscriptions: this.getSubscriptions()
        }

        await this.sendNewsletter({
          captchaKey: this.captchaKey,
          payload
        })

        await this.$refs.form.showSuccessMessage()
        this.isWidgetExpanded = false
        this.clearData()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    },
    clearData() {
      this.$helper.resetComponentData(
        this,
        this.$options.consts.resetComponentDataFields
      )
      this.$v.$reset()
    },
    getSubscriptions() {
      return this.checkboxes.reduce((acc, subscription) => {
        if (subscription.isSelected) {
          return `${acc},${subscription.value}`
        }
        return acc
      }, NEWSLETTER[NEWSLETTER_SOURCE.FL].MAIN)
    },
    expandWidget() {
      this.enableCaptcha()
      this.isWidgetExpanded = true
    },
    enableCaptcha() {
      this.isCaptchaEnabled = true
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-widget__wrapper {
  position: relative;
  padding: 20px;
  border: 3px solid $c--main;
  background-color: rgb(245, 245, 245);
  overflow: hidden;
  height: 116px;

  &--expanded {
    height: auto;
  }

  .newsletter-widget__title-section {
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
  }

  .newsletter-widget__icon {
    margin-left: -5px;
  }

  .newsletter-widget__title {
    margin-left: 8px;
    font-family: $font-roboto-condensed;
    font-size: 18px;
    font-weight: 700;
    color: $c--main;
  }

  .newsletter-widget__input {
    margin-bottom: 10px;

    &.email {
      margin-bottom: 20px;
    }
  }

  .newsletter-widget__checkboxes-section-title {
    margin: 10px 0;
  }

  .newsletter-widget__checkboxes-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .newsletter-widget__checkbox {
    width: auto;
    margin-bottom: 10px;
  }

  .newsletter-widget__button {
    width: 200px;
    margin: 0 auto;
  }

  .newsletter-widget__gdpr {
    max-width: 275px;

    /deep/ a {
      color: $c--main;
      text-decoration: none;
    }
  }

  .newsletter-widget__bottom-overlay {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    background: rgb(245, 245, 245);
  }
}
</style>
